import React, { Component } from 'react'

import ProfesseursDeTennisPage from '../containers/ProfesseursDeTennisPage'
import PrimaryLayout from '../components/layouts/PrimaryLayout'
import pagesInfos from '../config/i18n/pagesInfos'

const ProfesseursDeTennis = ({ pageContext }) => {
  return (
    <PrimaryLayout
      language={pageContext.language}
      isDisplayFromHome={false}
      page={pagesInfos.ComponentProfesseursDeTennis}
    >
      <ProfesseursDeTennisPage />
    </PrimaryLayout>
  )
}

export default ProfesseursDeTennis
