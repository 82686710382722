import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { Link } from 'react-scroll'
import BackgroundImage from 'gatsby-background-image'
import { useTranslation } from 'react-i18next'
import { cover } from 'polished'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Section from '../components/Section'
import Heading from '../components/Heading'
import Hero from '../components/Hero'
import Button from '../components/Button'
import Team from '../components/Team'
import Gallery from '../components/Gallery'

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(15),
  },
}))

const ProfesseursDeTennisPage = ({}) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const classes = useStyles()

  const data = useStaticQuery(graphql`
    query {
      bgEvents: file(relativePath: { eq: "bg_events.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Helmet
        title={t('equipe:head.title')}
        meta={[
          {
            name: 'description',
            content: t('equipe:head.description'),
          },
        ]}
      />

      <Section bg="#E7F1FE">
        <Typography
          component="h1"
          variant="h2"
          gutterBottom
          className={classes.title}
        >
          {t('equipe:title')}
        </Typography>

        <Typography component="p" variant="body2" gutterBottom>
          {t('equipe:text')}
        </Typography>
        <Team variant="primary" />
      </Section>
    </React.Fragment>
  )
}

ProfesseursDeTennisPage.defaultProps = {}

export default ProfesseursDeTennisPage
